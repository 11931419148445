<template>
<div class="row">
    <div class="container">
    <h5>Доставка металлопроката</h5>
    <p>Доставка по Челябинску может осуществляется траспортными компаниями "Яндекс.Такси" или "Двойки". Стоимость составляет примерно от 400 рублей / час, если используется
        грузовая бортовая ГАЗель грузоподъемностью до 1,5 тонн и возможностью перевезти груз длиной до 3 метров, или примерно 500 рублей / час, в случае ГАЗели с установкой, чтобы перевезти груз длиной до 6м.
    </p>
    <p>
        Также у нас есть собственный парк техники для перевозки, который состоит из тягачей МАЗ, перевозим до 10 тонн, длина груза до 6 м. Такой способ доставки стоит от 800 рублей за доставку, маршрут определяется заранее за 1 день.
    </p>
    <h5>Оплата</h5>
    <ul>
        <li>Наличный расчет. Оплата наличными при получении возможна при самовывозе и при курьерской доставке нашими машинами по Челябинску</li>
        <li>Безналичный расчет. Мы высылаем счет по электронной почте. Получатель от юридического лица должен иметь доверенность на получение товара</li>
   </ul>
   <h5>Реквизиты</h5>
   <p>ИП Ширяева Т.Ю. 454108, Челябинская обл. г. Челябинск, р/с 40802810807120006448 в ПАО “ЧЕЛИНДБАНК” г. Челябинск 744902939658</p>
</div></div>
</template>

<script>

export default {
  name: 'delivery',
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
    text-align: left;
    font-size: 1.4em;
}
li {
    text-align: left;
    font-size: 1.4em;
}
</style>
